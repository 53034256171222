
import { Component, Prop, Vue } from 'vue-property-decorator'

type ColorType = 'gray' | 'white'

@Component
export default class BaseButtonGroup extends Vue {
  @Prop({ default: false, type: Boolean })
  fluid!: boolean

  @Prop({ default: false })
  disabled!: boolean

  @Prop()
  classes!: string

  @Prop({ default: 'white' })
  color!: ColorType

  get isDisabled(): boolean {
    return this.disabled
  }
}
